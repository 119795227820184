import React, { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FormComponent from "../Components/ShimlaForm"; 
import "./Style.css"; 
import logo from '../../assets/skylogo.jpg';

const FormToPDF = () => {
  const [formData, setFormData] = useState({
    destination: "",
    guestName: "",
    days: "",
    travelDate: "",
    adults: 1,
    children: 0,
    mealPlan: "",
    vehicleType: "",
    departureTicket: "",
    returnTicket: "",
    resorts: [{ destination: "", checkInDate: "", checkOutDate: "", resortName: "", roomCategory: "", rate: "" }],
    inclusion: "",
    exclusion: "",
    rate: "",
    numRooms: 1,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addResort = () => {
    setFormData({
      ...formData,
      resorts: [
        ...formData.resorts,
        { destination: "", checkInDate: "", checkOutDate: "", resortName: "", roomCategory: "", rate: "" },
      ],
    });
  };

  const removeResort = (index) => {
    const updatedResorts = formData.resorts.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      resorts: updatedResorts,
    });
  };

  const handleResortChange = (index, e) => {
    const { name, value } = e.target;
    const updatedResorts = formData.resorts.map((resort, i) => {
      if (i === index) {
        return {
          ...resort,
          [name]: value,
        };
      }
      return resort;
    });

    setFormData({
      ...formData,
      resorts: updatedResorts,
    });
  };

  const printDocument = () => {
    const input = document.querySelector(".a4-box");
    html2canvas(input, { scale: 4, scrollX: 0, scrollY: 0 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.9);
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= 297;
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= 297;
      }
      const guestName = formData.guestName ? formData.guestName : "Guest";
      const pdfFileName = `${guestName}_${formData.destination || "Destination"}.pdf`;
      pdf.save(pdfFileName);
    });
  };

  return (
    <div className="FormToPDF">
      <FormComponent
        formData={formData}
        handleInputChange={handleInputChange}
        addResort={addResort}
        removeResort={removeResort}
        handleResortChange={handleResortChange}
      />

      <div id="divToPrint" className="pdf-preview">
        <div className="a4-box">
          <div className="header">
            <img src={logo} alt="SKY HOLIDAYS Logo" className="logo" />
            <div className="header-text">
              <h1>SKY HOLIDAYS</h1>
              <p>A-502, Steller, Sindhubhavan Road, Ahmedabad</p>
            </div>
          </div>

          <hr />
          <h2>{formData.destination} Package</h2>

          <table>
            <tbody>
              <tr><th>Destination</th><td>{formData.destination}</td></tr>
              <tr><th>Days</th><td>{formData.days}</td></tr>
              <tr><th>Guest Name</th><td>{formData.guestName}</td></tr>
              <tr>
                <th>Travel Date</th>
                <td>{new Date(formData.travelDate).toLocaleDateString('en-GB')}</td>
                </tr>
              <tr><th>Adults</th><td>{formData.adults}</td></tr>
              <tr><th>Children</th><td>{formData.children}</td></tr>
              <tr><th>Meal Plan</th><td>{formData.mealPlan}</td></tr>
              <tr><th>Vehicle</th><td>{formData.vehicleType}</td></tr>
              <tr><th>Number of Rooms</th><td>{formData.numRooms}</td></tr>
              <tr><th>Rate</th><td>{formData.rate}</td></tr>
            </tbody>
          </table>

          <div className="ticket-section">
            <div className="left">
              <h4>Departure Ticket</h4>
              <p>{formData.departureTicket}</p>
            </div>
            <div className="right">
              <h4>Return Ticket</h4>
              <p>{formData.returnTicket}</p>
            </div>
          </div>

          <h3>Resorts:</h3>
{formData.resorts.map((resort, index) => (
  <div className="resort-option custom-resort" key={index}>
    <h4>{resort.destination}</h4>
    
    <p>
      <span>Check-in Date:</span> <span className="bold-value">{new Date(resort.checkInDate).toLocaleDateString('en-GB')}</span>
    </p>
    
    <p>
      <span>Check-out Date:</span> <span className="bold-value">{new Date(resort.checkOutDate).toLocaleDateString('en-GB')}</span>
    </p>
    
    <p>
      <span>Resort Name:</span> <span className="bold-value">{resort.resortName}</span>
    </p>
    
    <p>
      <span>Room Category:</span> <span className="bold-value">{resort.roomType}</span>
    </p>
    
  </div>
))}


          <div className="price">
            <h4>Price: {formData.rate} </h4>
          </div>

          <div className="inclusion">
            <h4>Inclusion:</h4>
            <p>{formData.inclusion}</p>
          </div>
          <div className="inclusion">
            <h4>Exclusion:</h4>
            <p>{formData.exclusion}</p>
          </div>
          
        </div>
        
      </div>

      <div className="button-container">
        <button className="download-btn" onClick={printDocument}>Download PDF</button>
      </div>
    </div>
  );
};

export default FormToPDF;
