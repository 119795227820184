import React, { useEffect, useState, useRef, useCallback } from 'react';
import './AccountList.css';
import { FaPlus } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';

const AccountList = ({ accountData }) => {
  const [transactions, setTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [batchSize, setBatchSize] = useState(10); // Number of transactions to load at a time
  const observer = useRef(null); // To track the observer
  const navigate = useNavigate();

  // Load initial batch
  useEffect(() => {
    if (accountData && accountData.account_details) {
      setTransactions(accountData.account_details);
      setDisplayedTransactions(accountData.account_details.slice(0, batchSize));
    }
  }, [accountData, batchSize]);

  // Load more transactions when we reach the last item
  const loadMoreTransactions = useCallback(() => {
    const newBatch = transactions.slice(displayedTransactions.length, displayedTransactions.length + batchSize);
    setDisplayedTransactions(prev => [...prev, ...newBatch]);
  }, [transactions, displayedTransactions, batchSize]);

  // Intersection Observer callback for loading more data when in view
  const lastElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && displayedTransactions.length < transactions.length) {
        loadMoreTransactions();
      }
    });

    if (node) observer.current.observe(node);
  }, [loadMoreTransactions, displayedTransactions.length, transactions.length]);

  // Function to assign a class based on the category type
  const getCategoryClass = (category) => {
    switch (category.toLowerCase()) {
      case 'sales':
        return 'category-sales';
      case 'b2b':
        return 'category-b2b';
      case 'expenses':
        return 'category-expenses';
      case 'marketing':
        return 'category-marketing';
      default:
        return '';
    }
  };

  return (
    <div className="account-list-container">
      <div className="header">
        <button className="add-transaction-button" onClick={() => navigate('/addtransaction')}>
          <FaPlus className="add-icon" /> Add Transaction
        </button>
        <span className={`balance ${accountData.total >= 0 ? 'balance-positive' : 'balance-negative'}`}>
          Total Balance : <span>{accountData.total}</span>
        </span>
      </div>

      <div className='account-list-table-wrapper'>
        <table className="account-list-table">
          <thead>
            <tr>
              <th>Trip ID</th>
              <th>Date</th>
              <th>Description</th>
              <th>Category</th>
              <th>Amount</th>
              <th>Paid By/To</th>
              <th>Remark</th>
            </tr>
          </thead>
          <tbody>
            {displayedTransactions.map((transaction, index) => (
              <tr key={transaction.id} ref={index === displayedTransactions.length - 1 ? lastElementRef : null}>
                <td>
                  <Link to={`/tripdetails/${transaction.trip_id}`} className="link">
                    {transaction.trip_id}
                  </Link>
                </td>
                <td>{new Date(transaction.date).toLocaleDateString('en-GB')}</td>
                <td>{transaction.discriptions}</td>
                <td>
                  <span className={`category-box ${getCategoryClass(transaction.category)}`}>
                    {transaction.category}
                  </span>
                </td>
                <td>
                  <span className={`account-amount ${transaction.amount >= 0 ? 'account-amount-positive' : 'account-amount-negative'}`}>
                    <span>{transaction.amount}</span>
                  </span>
                </td>
                <td>{transaction.paid_by}</td>
                <td>{transaction.remark}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccountList;
