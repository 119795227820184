import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "../Quatation/Screen/Style.css"; // Import the CSS file
import logo from '../../src/assets/skylogo.jpg'; // Adjust the path as needed
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from "Components/Loader";
const FormToPDF = () => {
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null);
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL; // State to handle errors
  const [formData, setFormData] = useState({
    customer_name: "",
    customer_phone: "",
    departure_place: "",
    arrival_place: "",
    departure_date: "",
    return_date: "",
    departure_ticket: '',
    return_ticket: '',
    day: 0,
    night: 0,
    total_amount: 0,
    adult: 0,
    children: 0,
    vehicle: "",
    meal_plan: "",
    resort_details: [], // Array of resort objects fetched from API
    inclusion: "",
    remark: "",
  });

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        if (!token) {
          throw new Error('No token found');
        }
  
        const response = await axios.get(`${apiUrl}/api/v1/user/get-trip-details/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }); // Fetch trip data
  
        setFormData(response.data.data); // Assuming the data structure matches the required format
        setLoading(false); // Set loading to false once data is fetched
      } catch (err) {
        console.error('Error fetching trips:', err);
        setError('Failed to load trips'); // Set an error message if API call fails
        setLoading(false); // Set loading to false in case of error
      }
    };
  
    fetchData();
  }, [id]); // Include id as a dependency
  

  const printDocument = () => {
    const input = document.querySelector(".a4-box");

    html2canvas(input, { scale: 4, scrollX: 0, scrollY: 0 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.9);
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= 297;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= 297;
      }

      const pdfFileName = `${formData.customer_name || "Guest"}_${formData.departure_place || "Destination"}.pdf`;
      pdf.save(pdfFileName);
    });
  };

  if (loading) {
    return <Loader />; // Show a loading indicator while fetching data
  }

  if (error) {
    return <div>{error}</div>; // Show error message if API call fails
  }

  return (
    <div className="FormToPDF">
      <div id="divToPrint" className="pdf-preview">
        <div className="a4-box">
          <div className="header">
            <img src={logo} alt="SKY HOLIDAYS Logo" className="logo" />
            <div className="header-text">
              <h1>SKY HOLIDAYS</h1>
              <p>A-502, Steller, Sindhubhavan Road, Ahmedabad</p>
            </div>
          </div>
          <hr />
          <h2>{formData.destination} Package</h2>

          <table>
            <tbody>
            <tr>
                <th>Destination</th>
                <td>{formData.destination}</td>
              </tr>
              <tr>
                <th>Customer Name</th>
                <td>{formData.customer_name}</td>
              </tr>
              <tr>
                <th>Customer Phone</th>
                <td>{formData.customer_phone}</td>
              </tr>
              <tr>
                <th>Departure Place</th>
                <td>{formData.departure_place}</td>
              </tr>
              <tr>
                <th>Arrival Place</th>
                <td>{formData.arrival_place}</td>
              </tr>
              <tr>
                 <th>Departure Date</th>
                <td>{formData.departure_date ? `${String(new Date(formData.departure_date).getDate()).padStart(2, '0')}-${String(new Date(formData.departure_date).getMonth() + 1).padStart(2, '0')}-${new Date(formData.departure_date).getFullYear()}` : ''}</td>
                </tr>
                <tr>
                    <th>Return Date</th>
                    <td>{formData.return_date ? `${String(new Date(formData.return_date).getDate()).padStart(2, '0')}-${String(new Date(formData.return_date).getMonth() + 1).padStart(2, '0')}-${new Date(formData.return_date).getFullYear()}` : ''}</td>
                </tr>

              <tr>
                <th>Days</th>
                <td>{formData.day}</td>
              </tr>
              <tr>
                <th>Nights</th>
                <td>{formData.night}</td>
              </tr>
              <tr>
                <th>Total Amount</th>
                <td>{formData.total_amount}</td>
              </tr>
              <tr>
                <th>Adults</th>
                <td>{formData.adult}</td>
              </tr>
              <tr>
                <th>Children</th>
                <td>{formData.children}</td>
              </tr>
              <tr>
                <th>Vehicle</th>
                <td>{formData.vehicle}</td>
              </tr>
              <tr>
                <th>Meal Plan</th>
                <td>{formData.meal_plan}</td>
              </tr>
            </tbody>
          </table>
          <div className="ticket-section">
  <div className="left">
    <h4>Departure Ticket</h4>
    <p>{formData.departure_ticket}</p>
  </div>
  <div className="right">
    <h4>Return Ticket</h4>
    <p>{formData.return_ticket}</p>
  </div>
</div>
          <h3>Resort Details</h3>
          {Array.isArray(formData.resort_details) && formData.resort_details.length > 0 ? (
            formData.resort_details.map((resort, index) => (
              <div className="resort-option" key={index}>
             
                <div className="resort-details">
                  <h3>Resort {index + 1}</h3>
                  <h3>{resort.resortDate}</h3>
                  <h4>{resort.resortName}</h4>
                  <p className="star-rating">
                    {Array.from({ length: resort.star }, (_, i) => (
                      <span key={i}>⭐</span>
                    ))}
                  </p>
                  <p>{resort.roomCategory}</p>
                  
                </div>
              </div>
            ))
          ) : (
            <p>No Resort Details Available</p>
          )}

          <div className="inclusion">
            <h4>Inclusion:</h4>
            <p>{formData.inclusion}</p>
          </div>

          <div className="remark">
            <h4>Remark:</h4>
            <p>{formData.remark}</p>
          </div>
        </div>
      </div>

      <div className="button-container">
        <button className="download-btn" onClick={printDocument}>
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default FormToPDF;
