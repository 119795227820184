import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TripList from './TripList';
import Loader from '../../Components/Loader';
const OngoingTrip = () => {
  const [data, setData] = useState([]); // State to store fetched trips
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State to handle errors
  const apiUrl = process.env.REACT_APP_API_URL;
  // Fetch data from external API when the component mounts
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        if (!token) {
          throw new Error('No token found');
        }
  
        const response = await axios.get(`${apiUrl}/api/v1/user/get-trips?type=1`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }); // Replace with your actual API endpoint
  
        setData(response.data.data); // Assuming the data structure matches the required format
        setLoading(false); // Set loading to false once data is fetched
      } catch (err) {
        console.error('Error fetching trips:', err);
        setError('Failed to load trips'); // Set an error message if API call fails
        setLoading(false); // Set loading to false in case of error
      }
    };
  
    fetchTrips();
  }, []);
  

  // Render loading message, error message, or the TripList component
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return <TripList data={data} title="Ongoing Trips" />;
};

export default OngoingTrip;
