import React, { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import FormComponent from "../Components/GoaForm"; 
import "./Style.css";  // Import the CSS file
import logo from '../../assets/skylogo.jpg';
const FormToPDF = () => {
  const [formData, setFormData] = useState({
    destination: "",
    guestName: "",
    days: "",
    travelDate: "",
    arrival: "",
    departure: "",
    adults: 1,
    children: 0,
    mealPlan: "",
    vehicleType: "",
    departureTicket: "",
    returnTicket: "",
    resorts: [{ resortName: "", star: 1, roomCategory: "", rate: "", image: "" }],
    inclusion: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addResort = () => {
    setFormData({
      ...formData,
      resorts: [
        ...formData.resorts,
        { resortName: "", star: 1, roomCategory: "", rate: "", image: "" },
      ],
    });
  };

  const removeResort = (index) => {
    const updatedResorts = formData.resorts.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      resorts: updatedResorts,
    });
  };

  const handleResortChange = (index, e) => {
    const { name, value } = e.target;
    const updatedResorts = formData.resorts.map((resort, i) => {
      if (i === index) {
        return {
          ...resort,
          [name]: value,
        };
      }
      return resort;
    });

    setFormData({
      ...formData,
      resorts: updatedResorts,
    });
  };

  const printDocument = () => {
    const input = document.querySelector(".a4-box"); // Select the .a4-box element directly
  
    // Use html2canvas with a higher scale for better quality
    html2canvas(input, { scale: 4, scrollX: 0, scrollY: 0 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.9); // Convert to JPEG with 90% quality
  
      const pdf = new jsPDF("p", "mm", "a4");
  
      const imgWidth = 210; // Full A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
  
      let heightLeft = imgHeight;
      let position = 0;
  
      // First page
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= 297; // Subtract one full A4 height
  
      // Add extra pages as needed
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= 297;
      }
  
      // Generate PDF file name
      const guestName = formData.guestName ? formData.guestName : "Guest"; // Use 'Guest' if guestName is null or empty
      const pdfFileName = `${guestName}_${formData.destination || "Destination"}.pdf`;
      
      // Save the PDF with the generated file name
      pdf.save(pdfFileName);
    });
  };
  
  

  return (
    <div className="FormToPDF">
      <FormComponent
        formData={formData}
        handleInputChange={handleInputChange}
        addResort={addResort}
        removeResort={removeResort}
        handleResortChange={handleResortChange}
      />

      <div id="divToPrint" className="pdf-preview">
        <div className="a4-box">
          <div className="header">
            <img
              src=
              {logo} // Replace with actual logo path
              alt="SKY HOLIDAYS Logo"
              className="logo"
            />
            <div className="header-text">
              <h1>SKY HOLIDAYS</h1>
              <p>A-502, Steller, Sindhubhavan Road, Ahmedabad</p>
            </div>
          </div>

          <hr />
          <h2>{formData.destination} Package</h2>

<table>
  <tbody>
    <tr>
      <th>Destination</th>
      <td>{formData.destination}</td>
    </tr>
    <tr>
      <th>Days</th>
      <td>{formData.days}</td>
    </tr>
    <tr>
      <th>Guest Name</th>
      <td>{formData.guestName}</td>
    </tr>
    <tr>
      <th>Travel Date</th>
      <td>{formData.travelDate}</td>
    </tr>
    <tr>
      <th>Arrival</th>
      <td>{formData.arrival}</td>
    </tr>
    <tr>
      <th>Departure</th>
      <td>{formData.departure}</td>
    </tr>
    <tr>
      <th>Adults</th>
      <td>{formData.adults}</td>
    </tr>
    <tr>
      <th>Children</th>
      <td>{formData.children}</td>
    </tr>
    <tr>
      <th>Meal Plan</th>
      <td>{formData.mealPlan}</td>
    </tr>
    <tr>
      <th>Vehicle</th>
      <td>{formData.vehicleType}</td>
    </tr>
  </tbody>
</table>

<div className="ticket-section">
  <div className="left">
    <h4>Departure Ticket</h4>
    <p>{formData.departureTicket}</p>
  </div>
  <div className="right">
    <h4>Return Ticket</h4>
    <p>{formData.returnTicket}</p>
  </div>
</div>

<h3>Resorts:</h3>
{formData.resorts.map((resort, index) => (
  
  <div className="resort-option" key={index}>
    
    <img src={resort.image} alt={`Resort ${index + 1}`} />
    <div className="resort-details">
      <h3>Option {index + 1}</h3>
      <h4>{resort.resortName}</h4>
      <p className="star-rating">
        {/* Render stars based on resort.star value */}
        {Array.from({ length: resort.star }, (_, i) => (
          <span key={i}>⭐</span>
        ))}
        {/* Optionally add the text "Star" after the stars */}
       
      </p>
      <p>{resort.roomCategory}</p>
      <p className="price">Rate: {resort.rate} </p>
    </div>
  </div>
))}

<div className="inclusion">
  <h4>Inclusion:</h4>
  <p>{formData.inclusion}</p>
</div>



        </div>
        </div>

        <div className="button-container">
  <button className="download-btn" onClick={printDocument}>Download PDF</button>
</div>

    </div>
  );
};

export default FormToPDF;
