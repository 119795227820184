import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccountList from '../Component/AccountList';
import '../../Account/Screen/Account.css';
import TotalAccountComponent from '../Component/Total'; // Import your new componen
import Loader from '../../Components/Loader';
const AccountTabs = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [accountData, setAccountData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  // API endpoint base URL
  const apiUrl = process.env.REACT_APP_API_URL;
  
  // Function to fetch account data based on the active tab
  const fetchAccountData = async (tab) => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage
      if (!token) {
        throw new Error('No token found');
      }
  
      const response = await axios.get(`${apiUrl}/api/v1/user/get-account-details?category=${tab}`, {
        headers: {
          Authorization: `Bearer ${token}` // Add token to the Authorization header
        }
      });
  
      setAccountData(response.data.data);
    } catch (err) {
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };
  
  
  // Fetch data when the active tab changes
  useEffect(() => {
    fetchAccountData(activeTab);
  }, [activeTab]);

  return (
    <div className="account-tabs-container">
      <div className="tab-bar">
        <button onClick={() => setActiveTab('all')} className={`all ${activeTab === 'all' ? 'active' : ''}`}>All Transactions</button>
        <button onClick={() => setActiveTab('Sales')} className={`Sales ${activeTab === 'Sales' ? 'active' : ''}`}>Sales</button>
        <button onClick={() => setActiveTab('B2b')} className={`B2b ${activeTab === 'B2b' ? 'active' : ''}`}>B2B Payment</button>
        <button onClick={() => setActiveTab('Marketing')} className={`Marketing ${activeTab === 'Marketing' ? 'active' : ''}`}>Marketing</button>
        <button onClick={() => setActiveTab('Expenses')} className={`Expenses ${activeTab === 'Expenses' ? 'active' : ''}`}>Expenses</button>
        <button onClick={() => setActiveTab('Yash')} className={`Yash ${activeTab === 'Yash' ? 'active' : ''}`}>Yash</button>
        <button onClick={() => setActiveTab('Jaydip')} className={`Jaydip ${activeTab === 'Jaydip' ? 'active' : ''}`}>Jaydip</button>
        <button onClick={() => setActiveTab('Bhargav')} className={`Bhargav ${activeTab === 'Bhargav' ? 'active' : ''}`}>Bhargav</button>
        <button onClick={() => setActiveTab('total')} className={`total ${activeTab === 'total' ? 'active' : ''}`}>Total Account</button>
        
      </div>
      <div className="tab-content">
        {loading ? (
          <Loader />
        ) : error ? (
          <p>{error}</p>
        ) : activeTab === 'total' ? (
          <TotalAccountComponent /> 
        ) : (
          <AccountList accountData={accountData} />
        )}
      </div>
    </div>
  );
};

export default AccountTabs;
