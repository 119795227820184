import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Total.css'; // Include your custom CSS
import Loader from 'Components/Loader';

const TotalAccountComponent = () => {
  // State for storing amounts fetched from the API
  const [amountData, setAmountData] = useState({
    sales_total: 0,
    B2b_total: 0,
    Expenses_total: 0,
    marketing_total: 0,
    yash_total: 0,
    jaydip_total: 0,
    bhargav_total: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch amounts from the API
  const fetchAmounts = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage
      if (!token) {
        throw new Error('No token found');
      }
  
      const response = await axios.get(`${apiUrl}/api/v1/user/get-account-total`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      const data = response.data.data;
  
      setAmountData({
        sales_total: data.sales_total || 0,
        B2b_total: data.B2b_total || 0,
        Expenses_total: data.Expenses_total || 0,
        marketing_total: data.marketing_total || 0,
        yash_total: data.yash_total || 0,
        jaydip_total: data.jaydip_total || 0,
        bhargav_total: data.bhargav_total || 0,
      });
    } catch (err) {
      setError('Failed to fetch amounts from API');
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchAmounts();
  }, []);

  // Calculate total for types and persons
  const totalAmountForTypes =
    amountData.sales_total +
    amountData.Expenses_total +
    amountData.marketing_total +
    amountData.B2b_total;

  const totalAmountForPersons =
    amountData.yash_total + amountData.jaydip_total + amountData.bhargav_total;

  // Function to render amounts with positive or negative classes
  const renderAmount = (amount) => {
    const amountClass = amount >= 0 ? 'total-amount-positive' : 'total-amount-negative';
    return (
      <span className={`total-amount ${amountClass}`}>
        {amount}
      </span>
    );
  };

  return (
    <div className="total-account-container">
      {loading ? (
        <Loader />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="table-container">
          {/* First table for types */}
          <table className="type-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sales</td>
                <td>{renderAmount(amountData.sales_total)}</td>
              </tr>
              <tr>
                <td>Expenses</td>
                <td>{renderAmount(amountData.Expenses_total)}</td>
              </tr>
              <tr>
                <td>Marketing</td>
                <td>{renderAmount(amountData.marketing_total)}</td>
              </tr>
              <tr>
                <td>B2B</td>
                <td>{renderAmount(amountData.B2b_total)}</td>
              </tr>
              {/* Total row */}
              <tr className="total-row">
                <td>Total</td>
                <td>{renderAmount(totalAmountForTypes)}</td>
              </tr>
            </tbody>
          </table>

          {/* Second table for persons */}
          <table className="person-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Yash</td>
                <td>{renderAmount(amountData.yash_total)}</td>
              </tr>
              <tr>
                <td>Jaydip</td>
                <td>{renderAmount(amountData.jaydip_total)}</td>
              </tr>
              <tr>
                <td>Bhargav</td>
                <td>{renderAmount(amountData.bhargav_total)}</td>
              </tr>
              {/* Total row */}
              <tr className="total-row">
                <td>Total</td>
                <td>{renderAmount(totalAmountForPersons)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TotalAccountComponent;
