import React, { useState } from 'react';
import OngoingTrip from '../Components/OngoingTrip';
import CurrentTrip from '../Components/UpcomingTrip'; // Uses file name as UpcomingTrip
import CompletedTrip from '../Components/CompletedTrip';
import AllTrip from '../Components/AllList';
import './TripTabs.css';

const TripTabs = () => {
  const [activeTab, setActiveTab] = useState('ongoing');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'all':
        return <AllTrip />;
      case 'ongoing':
        return <OngoingTrip />;
      case 'upcoming':
        return <CurrentTrip />;
      case 'completed':
        return <CompletedTrip />;
      default:
        return <OngoingTrip />;
    }
  };

  return (
    <div className="trip-tabs">
      <div className="tab-bar">
        <button
          onClick={() => setActiveTab('all')}
          className={`all ${activeTab === 'all' ? 'active' : ''}`}
        >
          All Trip
        </button>
        <button
          onClick={() => setActiveTab('ongoing')}
          className={`ongoing ${activeTab === 'ongoing' ? 'active' : ''}`}
        >
          Ongoing Trip
        </button>
        <button
          onClick={() => setActiveTab('upcoming')}
          className={`upcoming ${activeTab === 'upcoming' ? 'active' : ''}`}
        >
          Upcoming Trip
        </button>
        <button
          onClick={() => setActiveTab('completed')}
          className={`completed ${activeTab === 'completed' ? 'active' : ''}`}
        >
          Completed Trip
        </button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default TripTabs;
