import React, { useState, useEffect, useRef, useCallback } from 'react';
import './TripList.css';
import { Link } from 'react-router-dom';

const TripList = ({ data }) => {
  const [displayedTrips, setDisplayedTrips] = useState([]);
  const [batchSize] = useState(10); // Number of trips to load at a time
  const [allTrips, setAllTrips] = useState(data); // All trips passed as prop
  const observer = useRef(null); // To track the observer

  // Load more trips when we reach the last item
  const loadMoreTrips = useCallback(() => {
    const newBatch = allTrips.slice(displayedTrips.length, displayedTrips.length + batchSize);
    setDisplayedTrips((prev) => [...prev, ...newBatch]);
  }, [allTrips, displayedTrips, batchSize]);

  // Intersection Observer callback for loading more data when in view
  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && displayedTrips.length < allTrips.length) {
          loadMoreTrips();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loadMoreTrips, displayedTrips.length, allTrips.length]
  );

  useEffect(() => {
    // Initially load the first batch of trips
    setDisplayedTrips(allTrips.slice(0, batchSize));
  }, [allTrips, batchSize]);

  return (
    <div className="trip-list-container">
      <div className="trip-table-wrapper">
        <table className="trip-table">
          <thead>
            <tr>
              <th>Trip ID</th>
              <th>Destination</th>
              <th>Starting</th>
              <th>Name</th>
              <th>Mobile No</th>
              <th>Departure Date</th>
              <th>Return Date</th>
              <th>Total Amount</th>
              <th>Paid Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {displayedTrips.length === 0 ? (
              <tr>
                <td colSpan="10">No trips available.</td>
              </tr>
            ) : (
              displayedTrips.map((trip, index) => {
                const isCompleted = trip.total_amount - trip.paid_amount <= 0;
                return (
                  <tr key={trip.trip_id} ref={index === displayedTrips.length - 1 ? lastElementRef : null}>
                    <td>
                      <Link to={`/tripdetails/${trip.trip_id}`} className="link">
                        {trip.trip_id}
                      </Link>
                    </td>
                    <td>{trip.destination}</td>
                    <td>{trip.starting_place}</td>
                    <td>{trip.customer_name}</td>
                    <td>{trip.customer_phone}</td>
                    <td>{new Date(trip.departure_date).toLocaleDateString('en-GB')}</td>
                    <td>{new Date(trip.return_date).toLocaleDateString('en-GB')}</td>
                    <td>{trip.total_amount}</td>
                    <td>{trip.paid_amount}</td>
                    <td>
                      <span className={`statustrip ${isCompleted ? 'completed' : 'pending'}`}>
                        {isCompleted ? 'Completed' : 'Pending'}
                      </span>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TripList;
