import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccountList from '../Components/AccountList';
import TotalAccountComponent from '../Components/Total';
import Loader from '../../Components/Loader';
import './Account.css';
import { debounce } from 'lodash';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import skylogo from '../../assets/search.png';

const AccountTabs = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [accountData, setAccountData] = useState([]);
  const [filteredAccountData, setFilteredAccountData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const tabNames = [
    { key: 'all', label: 'All Transactions' },
    { key: 'Sales', label: 'Sales' },
    { key: 'B2b', label: 'B2B Payment' },
    { key: 'Marketing', label: 'Marketing' },
    { key: 'Expenses', label: 'Expenses' },
    { key: 'Yash', label: 'Yash' },
    { key: 'Jaydip', label: 'Jaydip' },
    { key: 'Bhargav', label: 'Bhargav' },
    { key: 'total', label: 'Total Account' },
  ];

  const fetchAccountData = async (tab) => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get(`${apiUrl}/api/v1/user/get-account-details?category=${tab}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAccountData(response.data.data);
      setFilteredAccountData(response.data.data); // Initialize filtered data
    } catch (err) {
      setError('Error fetching data.');
    } finally {
      setLoading(false);
    }
  };

  const fetchTotalAccountData = async () => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get(`${apiUrl}/api/v1/user/get-account-details?category=all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAccountData(response.data.data);
      setFilteredAccountData(response.data.data); // Initialize filtered data
    } catch (err) {
      setError('Error fetching total data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'total') {
      fetchTotalAccountData();
    } else {
      fetchAccountData(activeTab);
    }
  }, [activeTab]);

  const handleSearchDebounced = debounce(async (searchValue) => {
    if (!searchValue) {
      setFilteredAccountData(accountData); // Reset to original data if search is empty
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get(`${apiUrl}/api/v1/user/get-account-details?category=${activeTab}&search=${searchValue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setFilteredAccountData(response.data.data);
    } catch (error) {
      console.error('Error fetching filtered data:', error);
      setFilteredAccountData([]); // Empty result on error
    }
  }, 300);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    handleSearchDebounced(searchValue);
  };

  const handleSearchButtonClick = () => {
    handleSearchDebounced(searchTerm);
  };

  useEffect(() => {
    return () => {
      handleSearchDebounced.cancel(); // Cleanup debounce on unmount
    };
  }, []);

  return (
    <div className="account-tabs-container">
      <div className="tab-bar">
        {tabNames.map((tab) => (
          <button
            key={tab.key}
            onClick={() => {
              setSearchTerm(''); // Reset search term when switching tabs
              setActiveTab(tab.key);
            }}
            className={`${tab.key} ${activeTab === tab.key ? 'active' : ''}`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {activeTab !== 'total' && (
       
          <div className="search-account-container">
            <input
              type="text"
              placeholder="Search here..."
              value={searchTerm}
              onChange={handleSearch}
              className="search-input"
            />
            <button className="search-button">
              <img src={skylogo} alt="Search" className="search-icon" />
            </button>
          </div>
     
      )}

      <div className="tab-content">
        {loading ? (
          <Loader />
        ) : error ? (
          <p>{error}</p>
        ) : activeTab === 'total' ? (
          <TotalAccountComponent accountData={accountData} />
        ) : (
          <AccountList accountData={filteredAccountData} />
        )}
      </div>
    </div>
  );
};

export default AccountTabs;
