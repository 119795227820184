import React, { useState } from 'react';
import GoaTab from './GoaScreen';
import ShimlaTab from './ShimlaScreen';
import '../../Trips/Screen/TripTabs.css';

const TripTabs = () => {
  const [activeTab, setActiveTab] = useState('goa');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'goa trip':
        return <GoaTab />;
      case 'shimla':
        return <ShimlaTab />;
      default:
        return <GoaTab />;
    }
  };

  return (
    <div className="trip-tabs">
      <div className="tab-bar">
        <button
          onClick={() => setActiveTab('goa')}
          className={`goa ${activeTab === 'goa' ? 'active' : ''}`}
        >
          Goa
        </button>
        <button
          onClick={() => setActiveTab('shimla')}
          className={`shimla ${activeTab === 'shimla' ? 'active' : ''}`}
        >
          Shimla
        </button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default TripTabs;
