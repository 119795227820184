import React, { useState } from 'react';
import '../../Quatation/Components/Form.css'; // Ensure you import the CSS file
import TextareaAutosize from "react-textarea-autosize";

const AddTripForm = () => {
    // State initialization with default values
    const [formData, setFormData] = useState({
        destination: '',
        starting_place: '',
        customer_name: '',
        customer_phone: '',
        departure_place: '',
        arrival_place: '',
        departure_date: '',
        return_date: '',
        departure_ticket: '',
        return_ticket: '',
        day: 0,
        night: 0,
        total_amount: 0,
        paid_amount: 0,
        adult: 0,
        children: 0,
        vehicle: '',
        meal_plan: '',
        inclusion: '',
        remark: '',
        resort_details: [], // Array to store multiple resort details
    });

    const [newResort, setNewResort] = useState({
        resort_name: '',
        resort_date: '',
        resort_star: '',
        resort_category: '',
    });

    // Handle input changes for form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle input changes for new resort fields
    const handleResortChange = (index, e) => {
        const { name, value } = e.target;
        setFormData((prevState) => {
            const updatedResorts = [...prevState.resort_details];
            updatedResorts[index] = {
                ...updatedResorts[index],
                [name]: value,
            };
            return {
                ...prevState,
                resort_details: updatedResorts,
            };
        });
    };

    // Function to add a new resort to the resort array
    const addResort = () => {
        const newResort = { resortName: '', star: '', roomCategory: '', rate: '', resortDate: '' };
        setFormData((prevState) => ({
            ...prevState,
            resort_details: [...prevState.resort_details, newResort], // Add new empty resort
        }));
    };

    // Function to remove a resort by index
    const removeResort = (index) => {
        const updatedResorts = formData.resort_details.filter((_, i) => i !== index);
        setFormData({ ...formData, resort_details: updatedResorts });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const apiUrl = process.env.REACT_APP_API_URL;

        try {
            const response = await fetch(`${apiUrl}/api/v1/user/create-trip`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}` // Add token to the Authorization header
                },
                body: JSON.stringify(formData),
              });
              
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              

            const result = await response.json();
            console.log('Success:', result);

            // Optionally reset form data
            setFormData({
                destination: '',
                starting_place: '',
                customer_name: '',
                customer_phone: '',
                departure_place: '',
                arrival_place: '',
                departure_date: '',
                return_date: '',
                departure_ticket: '',
                return_ticket: '',
                day: 0,
                night: 0,
                total_amount: 0,
                paid_amount: 0,
                adult: 0,
                children: 0,
                vehicle: '',
                meal_plan: '',
                inclusion: '',
                remark: '',
                resort_details: [], // Clear the resorts array
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="form-to-pdf">
            <h2>Add Trip Details</h2>
            <form onSubmit={handleSubmit}>
                {/* Existing form fields for trip details */}
                <label>Destination:</label>
                <input
                    type="text"
                    name="destination"
                    value={formData.destination}
                    onChange={handleChange}
                    placeholder="Destination"
                    required
                />
                <label>Starting:</label>
                <input
                    type="text"
                    name="starting_place"
                    value={formData.starting_place}
                    onChange={handleChange}
                    placeholder="Starting Point"
                    required
                />
                <label>Guest Name:</label>
                <input
                    type="text"
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={handleChange}
                    placeholder="Guest Name"
                    required
                />
                <label>Mobile No:</label>
                <input
                    type="number"
                    name="customer_phone"
                    value={formData.customer_phone}
                    onChange={handleChange}
                    placeholder="Mobile No"
                    required
                />
                <label>Adults:</label>
                <input
                    type="number"
                    name="adult"
                    value={formData.adult}
                    onChange={handleChange}
                    placeholder="Number of Adults"
                    min="0"
                    required
                />
                <label>Children:</label>
                <input
                    type="number"
                    name="children"
                    value={formData.children}
                    onChange={handleChange}
                    placeholder="Number of Children"
                    min="0"
                    required
                />
                <label>Departure Place:</label>
                <input
                    type="text"
                    name="departure_place"
                    value={formData.departure_place}
                    onChange={handleChange}
                    placeholder="Departure Place"
                    
                />
                <label>Arrival Place:</label>
                <input
                    type="text"
                    name="arrival_place"
                    value={formData.arrival_place}
                    onChange={handleChange}
                    placeholder="Arrival Place"
                    
                />
                <label>Departure Date:</label>
                <input
                    type="date"
                    name="departure_date"
                    value={formData.departure_date}
                    onChange={handleChange}
                    required
                />
                <label>Return Date:</label>
                <input
                    type="date"
                    name="return_date"
                    value={formData.return_date}
                    onChange={handleChange}
                    
                />
                <label>Departure Ticket:</label>
                <TextareaAutosize
                    name="departure_ticket"
                    value={formData.departure_ticket}
                    onChange={handleChange}
                    minRows={2}
                    placeholder="Enter departure ticket details..."
                />
                <label>Return Ticket:</label>
                <TextareaAutosize
                    name="return_ticket"
                    value={formData.return_ticket}
                    onChange={handleChange}
                    minRows={2}
                    placeholder="Enter return ticket details..."
                />
                <label>Days:</label>
                <input
                    type="number"
                    name="day"
                    value={formData.day}
                    onChange={handleChange}
                    placeholder="Days"
                    min="1"
                    required
                />
                <label>Nights:</label>
                <input
                    type="number"
                    name="night"
                    value={formData.night}
                    onChange={handleChange}
                    placeholder="Nights"
                    min="1"
                    required
                />
                <label>Total Amount:</label>
                <input
                    type="number"
                    name="total_amount"
                    value={formData.total_amount}
                    onChange={handleChange}
                    placeholder="Total Amount"
                    required
                />
                <label>Paid Amount:</label>
                <input
                    type="number"
                    name="paid_amount"
                    value={formData.paid_amount}
                    onChange={handleChange}
                    placeholder="Paid Amount"
                    
                />
                <label>Vehicle:</label>
                <select
                    name="vehicle"
                    value={formData.vehicle}
                    onChange={handleChange}
                    
                >
                    <option value="">Select Vehicle</option>
                    <option value="Sedan">Sedan</option>
                    <option value="No Vehical"> No Vehical</option>
                    <option value="Activa">Bus</option>
                    <option value="Van">Van</option>
                    <option value="Bike">Bike</option>
                    <option value="Winger 10-12">Winger 10-12</option>
                </select>
                <label>Meal Plan:</label>
                <select
                    name="meal_plan"
                    value={formData.meal_plan}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Meal Plan</option>
                    <option value="Breakfast-Dinner">Breakfast-Dinner</option>
                    <option value="Morning Breakfast">Morning Breakfast</option>
                    <option value="Lunch">Lunch</option>
                    <option value="Dinner">Dinner</option>
                    <option value="All Inclusive">All Inclusive</option>
                </select>
                <label>Inclusion:</label>
                <TextareaAutosize
                    name="inclusion"
                    value={formData.inclusion}
                    onChange={handleChange}
                    placeholder="Inclusions in the trip"
                    required
                    minRows={2}
                />
                <label>Remark:</label>
                <TextareaAutosize
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                    placeholder="Additional Remarks"
                    minRows={2}
                />

                {/* Resort Details Section */}
                <h3>Resort Details</h3>
                {formData.resort_details.map((resort, index) => (
                <div className="resort-option" key={index}>
                    <h4>{`Resort ${index + 1}`}</h4>
                    <label>Resort Name:</label>
                    <input
                        type="text"
                        name="resortName"
                        value={resort.resortName}
                        onChange={(e) => handleResortChange(index, e)}
                        placeholder="Resort Name"
                    />
                    <label>Star Rating:</label>
                    <input
                        type="number"
                        name="star"
                        value={resort.star}
                        onChange={(e) => handleResortChange(index, e)}
                        placeholder="Star Rating"
                    />
                    <label>Room Category:</label>
                    <input
                        type="text"
                        name="roomCategory"
                        value={resort.roomCategory}
                        onChange={(e) => handleResortChange(index, e)}
                        placeholder="Room Category"
                    />
                    <label>Rate:</label>
                    <input
                        type="number"
                        name="rate"
                        value={resort.rate}
                        onChange={(e) => handleResortChange(index, e)}
                        placeholder="Rate"
                    />
                    <label>Resort Date:</label>
                    <input
                        type="text"
                        name="resortDate"
                        value={resort.resortDate}
                        onChange={(e) => handleResortChange(index, e)}
                    />
                    <button
                        type="button"
                        onClick={() => removeResort(index)}
                        className="remove-resort-button"
                    >
                        Remove
                    </button>
                </div>
            ))}
            <button type="button" onClick={addResort} className="add-resort-button">
                Add Resort
            </button>
                <button type="submit" className="submit-btn">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default AddTripForm;
