import React, { useState, useEffect } from 'react';
import '.././Trips/Components/Alllist.css';
import { FaPlus } from 'react-icons/fa'; // Importing icons from react-icons
import axios from 'axios'; // Importing axios for API calls
import { useNavigate } from 'react-router-dom';
import skylogo from '../../src/assets/search.png';
import Loader from '../../src/Components/Loader';
import { Link } from 'react-router-dom';
const AllTripList = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [alltrips, setAllTrips] = useState([]); // Changed initial value to an empty array
    const [filteredAllTrips, setFilteredAllTrips] = useState([]);
    const [loading, setLoading] = useState(true); 
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        const fetchAllTrips = async () => {
            try {
                const token = localStorage.getItem('token'); // Retrieve token from localStorage
                if (!token) {
                    throw new Error('No token found');
                }
    
                const response = await axios.get(`${apiUrl}/api/v1/user/get-trips`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }); // Replace with your API for all trips
    
                setAllTrips(response.data.data);
                setFilteredAllTrips(response.data.data); // Set filtered trips to the fetched data
            } catch (error) {
                console.error('Error fetching trips:', error);
            } finally {
                setLoading(false); // Set loading to false when data is fetched
            }
        };
    
        fetchAllTrips();
    }, []);
    
    
    // Render loader while loading
    if (loading) {
        return <Loader />;  // Show loader while data is being fetched
    }
   
   
    // Handle search input change and fetch filtered trips
    const handleSearch = async (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearchTerm(searchValue);
    
        // If the search value is empty, reset the filtered trips to the original
        if (searchValue === '') {
            setFilteredAllTrips(alltrips);
        } else {
            try {
                const token = localStorage.getItem('token'); // Retrieve token from localStorage
                if (!token) {
                    throw new Error('No token found');
                }
    
                const response = await axios.get(`${apiUrl}/api/v1/user/get-trips?search=${searchValue}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }); // Replace with your search API
    
                setFilteredAllTrips(response.data.data); // Assuming the response contains the filtered trips
            } catch (error) {
                console.error('Error fetching filtered trips:', error);
                setFilteredAllTrips([]); // Set to empty if there's an error
            }
        }
    };
    

    return (
        <div className="alltrip-list-container">
            {/* Top section for search input and add trip button */}
            <div className="alltrip-list-header">
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Search here..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-input"
                    />
                    <button className="search-button">
                        <img src={skylogo} alt="Search" className="search-icon" /> {/* Search image */}
                    </button>
                </div>
                
            </div>

            {/* All Trip Table */}
            <div className='alltrip-table-wrapper'>
            <table className="alltrip-table">
                <thead>
                    <tr>
                        <th>Trip ID</th>
                        <th>Destination</th>
                        <th>Starting</th>
                        <th>Name</th>
                        <th>Mobile No</th>
                        <th>Departure Date</th>
                        <th>Return Date</th>
                        <th>Total Amount</th>
                        <th>Paid Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredAllTrips.length === 0 ? (
                        <tr>
                            <td colSpan="10">No trips available.</td>
                        </tr>
                    ) : (
                        filteredAllTrips.map((alltrip) => {
                            const isCompleted = alltrip.total_amount - alltrip.paid_amount === 0;
                            return (
                                <tr key={alltrip.trip_id}>
                                    <td>
                                        <Link to={`/invoices/details/${alltrip.trip_id}`} className="link">
                                            {alltrip.trip_id}
                                        </Link>
                                    </td>
                                    <td>{alltrip.destination}</td>
                                    <td>{alltrip.starting_place}</td>
                                    <td>{alltrip.customer_name}</td>
                                    <td>{alltrip.customer_phone}</td>
                                    <td>{new Date(alltrip.departure_date).toLocaleDateString('en-GB')}</td>
                                    <td>{new Date(alltrip.return_date).toLocaleDateString('en-GB')}</td>
                                    
                                    <td>{alltrip.total_amount}</td>
                                    <td>{alltrip.paid_amount}</td>
                                    <td>
                                        <span className={`statustrip ${isCompleted ? 'completed' : 'pending'}`}>
                                            {isCompleted ? 'Completed' : 'Pending'}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default AllTripList;
