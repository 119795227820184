import React, { useState } from 'react';
import './Login.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const LoginBox = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(''); // State for messages
  const [isError, setIsError] = useState(false); // State to determine if the message is an error
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make API call
      const response = await fetch(`${apiUrl}/api/v1/auth/admin-login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      // Parse JSON response
      const result = await response.json();
      

      if (!response.ok) {
        throw new Error(result.message || 'Login failed. Please check your credentials.');
      }

      // Extract and validate role
      const token = result?.data?.token;
      const role = result?.data?.role;

      if (!token || role === undefined) {
        throw new Error('Invalid response from the server: Missing token or role.');
      }

      // Save token and role in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);

      
      setIsError(false);

      // Redirect based on role
      if (role === '1') {
        setMessage('Login successful in SkyHolidays!');
        setTimeout(() => navigate('/home'), 1000); // Redirect to SkyHolidays
      } else if (role === '0') {
        setMessage('Login successful in SkyLink!');
        setTimeout(() => navigate('/skylink'), 1000); // Redirect to SkyLink
      } else {
        throw new Error('Login successful, but role is not recognized.');
      }
    } catch (error) {
      console.error('Error during login:', error); // Log the error for debugging
      setMessage(error.message || 'An error occurred. Please try again later.');
      setIsError(true);
    }
  };

  return (
    <div className="login-box">
      <h2>Login</h2>
      {message && (
        <div className={`message-box ${isError ? 'error' : 'success'}`}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Login</button>
      </form>
    </div>
  );
};

export default LoginBox;
