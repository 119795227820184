import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Navbar";
import SkyNavbar from "./Components/SkyNav";
import Home from "./Trips/Screen/TripTabs";
import Quotation from "./Quatation/Screen/QuatationTab";
import Trips from "./Trips/Screen/TripTabs";
import Addtrip from "./Trips/Screen/AddTrip";
import Account from "./Account/Screen/Account";
import SkyAccount from "./SkyLink/Screen/Account";
import Addtransaction from "./Account/Screen/AccountForm";
import SkyAddtransaction from "./SkyLink/Screen/AccountForm";
import TripDetails from "./TripDetails/TripDetails";
import Invoices from "./Invoice/Invoice";
import InvoiceDetails from "./Invoice/Component/InvoiceDetails";
import LoginBox from "./login/Login";

// Protected Route Component
const ProtectedRoute = ({ role, requiredRole, children }) => {
  const token = localStorage.getItem("token");
  if (!token || role === null) {
    return <Navigate to="/login" replace />;
  }

  if (role !== requiredRole) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function App() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const role = token ? parseInt(localStorage.getItem("role"), 10) : null;

  const isSkyLinkRoute = location.pathname.startsWith("/skylink");

  return (
    <div className="App">
      {/* Navbar */}
      {location.pathname !== "/login" && (isSkyLinkRoute ? <SkyNavbar /> : <Navbar />)}

      <Routes>
        {/* SkyHolidays Routes */}
        <Route
          path="/home"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quotations"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <Quotation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/trips"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <Trips />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addtrip"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <Addtrip />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addtransaction"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <Addtransaction />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tripdetails/:id"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <TripDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <Invoices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices/details/:id"
          element={
            <ProtectedRoute role={role} requiredRole={1}>
              <InvoiceDetails />
            </ProtectedRoute>
          }
        />

        {/* SkyLink Routes */}
        <Route
          path="/skylink"
          element={
            <ProtectedRoute role={role} requiredRole={0}>
              <SkyAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/skyaddtransaction"
          element={
            <ProtectedRoute role={role} requiredRole={0}>
              <SkyAddtransaction />
            </ProtectedRoute>
          }
        />

        {/* Login Route */}
        <Route path="/login" element={<LoginBox />} />

        {/* Redirect for unmatched routes */}
        <Route
          path="*"
          element={
            <Navigate to={role === 1 ? "/home" : role === 0 ? "/skylink" : "/login"} replace />
          }
        />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
