import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import './Form.css';

const FormComponent = ({ formData, handleInputChange, addResort, removeResort, handleResortChange }) => {
  return (
    <div className="form-to-pdf">
      <h2>Quotation Form</h2>

      <label>Destination:</label>
      <input
        type="text"
        name="destination"
        value={formData.destination}
        onChange={handleInputChange}
      />

      <label>Guest Name:</label>
      <input
        type="text"
        name="guestName"
        value={formData.guestName}
        onChange={handleInputChange}
      />

      <label>Days:</label>
      <input
        type="text"
        name="days"
        value={formData.days}
        onChange={handleInputChange}
      />

      <label>Travel Date:</label>
      <input
        type="date"
        name="travelDate"
        value={formData.travelDate}
        onChange={handleInputChange}
      />

      <label>Rate:</label>
      <input
        type="text"
        name="rate"
        value={formData.rate}
        onChange={handleInputChange}
      />

      <label>Number of Rooms:</label>
      <input
        type="number"
        name="numRooms"
        value={formData.numRooms}
        onChange={handleInputChange}
        min="1"
      />

      <label>Adults:</label>
      <input
        type="number"
        name="adults"
        value={formData.adults}
        onChange={handleInputChange}
        min="1"
      />

      <label>Children:</label>
      <input
        type="number"
        name="children"
        value={formData.children}
        onChange={handleInputChange}
        min="0"
      />

      <label>Meal Plan:</label>
      <select name="mealPlan" value={formData.mealPlan} onChange={handleInputChange}>
        <option value="">Select a meal plan</option>
        <option value="Breakfast-Dinner">Breakfast-Dinner</option>
        <option value="Breakfast Only">Breakfast Only</option>
      </select>

      <label>Vehicle Type:</label>
      <select name="vehicleType" value={formData.vehicleType} onChange={handleInputChange}>
        <option value="">Select a vehicle type</option>
        <option value="Sedan">Sedan</option>
        <option value="Ertiga">Ertiga</option>
        <option value="Activa">Activa</option>
        <option value="No Vehicle">No Vehicle</option>
      </select>

      <label>Departure Ticket:</label>
      <TextareaAutosize
        name="departureTicket"
        value={formData.departureTicket}
        onChange={handleInputChange}
        minRows={2}
        placeholder="Enter departure ticket details..."
      />

      <label>Return Ticket:</label>
      <TextareaAutosize
        name="returnTicket"
        value={formData.returnTicket}
        onChange={handleInputChange}
        minRows={2}
        placeholder="Enter return ticket details..."
      />

      <h3>Resorts:</h3>
      {formData.resorts.map((resort, index) => (
        <div className="resort-option" key={index}>
          <h4>{`Option ${index + 1}`}</h4>

          <label>Destination:</label>
          <input
            type="text"
            name="destination"
            value={resort.destination}
            onChange={(e) => handleResortChange(index, e)}
          />

          <label>Resort Name:</label>
          <input
            type="text"
            name="resortName"
            value={resort.resortName}
            onChange={(e) => handleResortChange(index, e)}
          />

          <label>Room Type:</label>
          <input
            type="text"
            name="roomType"
            value={resort.roomType}
            onChange={(e) => handleResortChange(index, e)}
          />

          <label>Check-in Date:</label>
          <input
            type="date"
            name="checkInDate"
            value={resort.checkInDate}
            onChange={(e) => handleResortChange(index, e)}
          />

          <label>Check-out Date:</label>
          <input
            type="date"
            name="checkOutDate"
            value={resort.checkOutDate}
            onChange={(e) => handleResortChange(index, e)}
          />

          <button type="button" className="remove-resort-button" onClick={() => removeResort(index)}>
            Remove Resort
          </button>
        </div>
      ))}
      <button type="button" className="add-resort-button" onClick={addResort}>Add Resort</button>

      <label>Inclusion:</label>
      <TextareaAutosize
        name="inclusion"
        value={formData.inclusion}
        onChange={handleInputChange}
        minRows={2}
        placeholder="Enter inclusion details..."
      />
      
      <label>Exclusion:</label>
      <TextareaAutosize
        name="exclusion"
        value={formData.exclusion}
        onChange={handleInputChange}
        minRows={2}
        placeholder="Enter exclusion details..."
      />

      <button type="submit" className="submit-button">Submit</button>
    </div>
  );
};

export default FormComponent;
