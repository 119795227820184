import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons'; // Import necessary icons
import './Navbar.css';
import logo from '../../src/assets/sky.png'; // Import your logo

const Navbar = () => {
  const navigate = useNavigate();
  const [showCategories, setShowCategories] = useState(false);
  const categoriesRef = useRef(null); // Ref for categories modal
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [role, setRole] = useState(localStorage.getItem('role'));

  const handleLogout = () => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
  
    // Check if the token exists before proceeding
    if (token) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      console.log('Token and role removed successfully');
  
      // Set token and role state to null if they exist
      setToken(null); // Assuming you have setToken defined
      setRole(null); // Assuming you have setRole defined
    } else {
      console.log('No token found to remove');
    }
  
    // Optionally log the current state of localStorage for debugging
    console.log('Current localStorage:', localStorage);
  
    // Navigate to the login page after logout
    navigate('/login');
    window.location.reload(); 
  };
  

  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  const handleCategoryClick = () => {
    setShowCategories(false); // Close modal on category selection
  };

  // Handle closing the modal if clicking outside
  const handleClickOutside = (event) => {
    if (categoriesRef.current && !categoriesRef.current.contains(event.target)) {
      setShowCategories(false);
    }
  };

  useEffect(() => {
    if (showCategories) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCategories]);

  return (
    <>
      {/* Desktop View */}
      <nav className="navbar">
        <div className="navbar-brand">
          <img src={logo} alt="SKY HOLIDAYS Logo" className="navbar-logo" />
          <span className="navbar-title">SKY HOLIDAYS</span>
        </div>
        <div className="navbar-center">
          <ul className="navbar-options">
            <li><Link to="/trips">Trips</Link></li>
            <li><Link to="/account">Accounts</Link></li>
            <li><Link to="/quotations">Quotations</Link></li>
            <li><Link to="/invoices">Invoices</Link></li>
          </ul>
        </div>
        <div className="navbar-logout">
          <button onClick={handleLogout} className="logout-btn">
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
          </button>
        </div>
      </nav>

      {/* Mobile View */}
      <div className="navbar-mobile">
        <div className="navbar-brand-mobile">
          <img src={logo} alt="SKY HOLIDAYS Logo" className="navbar-logo" />
          <span className="navbar-title">SKY HOLIDAYS</span>
        </div>
        <button className="categories-btn" onClick={toggleCategories}>
          <FontAwesomeIcon icon={faBars} />
        </button>

        {/* Left-aligned Modal in mobile view */}
        {showCategories && (
          <div className="categories-modal" ref={categoriesRef}>
            <div className="categories-content">
              <ul>
                <li><Link to="/trips" onClick={handleCategoryClick}>Trips</Link></li>
                <li><Link to="/account" onClick={handleCategoryClick}>Accounts</Link></li>
                <li><Link to="/quotations" onClick={handleCategoryClick}>Quotations</Link></li>
                <li><Link to="/invoices" onClick={handleCategoryClick}>Invoices</Link></li>
                <li>
                  <button onClick={handleLogout} className="logout-btn">
                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
