import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Alllist.css';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import skylogo from '../../assets/search.png';
import Loader from '../../Components/Loader';

const AllTripList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [allTrips, setAllTrips] = useState([]);
  const [displayedTrips, setDisplayedTrips] = useState([]);
  const [filteredTrips, setFilteredTrips] = useState([]); // Store filtered trips separately
  const [batchSize] = useState(10); // Number of trips to load at a time
  const observer = useRef(null); // To track the observer

  useEffect(() => {
    const fetchAllTrips = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/api/v1/user/get-trips`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setAllTrips(response.data.data);
        setFilteredTrips(response.data.data); // Initially, no filter
        setDisplayedTrips(response.data.data.slice(0, batchSize)); // Load first batch of trips
      } catch (error) {
        console.error('Error fetching trips:', error);
      }
    };

    fetchAllTrips();
  }, [batchSize]);

  // Load more trips when we reach the last item
  const loadMoreTrips = useCallback(() => {
    const newBatch = filteredTrips.slice(displayedTrips.length, displayedTrips.length + batchSize);
    setDisplayedTrips((prev) => [...prev, ...newBatch]);
  }, [filteredTrips, displayedTrips, batchSize]);

  // Intersection Observer callback for loading more data when in view
  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && displayedTrips.length < filteredTrips.length) {
          loadMoreTrips();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loadMoreTrips, displayedTrips.length, filteredTrips.length]
  );

  // Handle search input change
  const handleSearch = async (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    if (searchValue === '') {
      setFilteredTrips(allTrips); // If no search, show all trips
      setDisplayedTrips(allTrips.slice(0, batchSize)); // Load first batch
    } else {
      const filtered = allTrips.filter(
        (trip) =>
          trip.destination.toLowerCase().includes(searchValue) ||
          trip.trip_id.toString().includes(searchValue) ||
          trip.customer_name.toLowerCase().includes(searchValue) // Check for customer name
      );
      setFilteredTrips(filtered); // Set filtered trips
      setDisplayedTrips(filtered.slice(0, batchSize)); // Load first batch of filtered results
    }
  };

  if (!allTrips.length) {
    return <Loader />; // Show loader while data is being fetched
  }

  return (
    <div className="alltrip-list-container">
      {/* Top section for search input and add trip button */}
      <div className="alltrip-list-header">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search here..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          <button className="search-button">
            <img src={skylogo} alt="Search" className="search-icon" />
          </button>
        </div>
        <button className="add-trip-button" onClick={() => navigate('/addtrip')}>
          <FaPlus className="add-icon" /> Add Trip
        </button>
      </div>

      {/* All Trip Table */}
      <div className="alltrip-table-wrapper">
        <table className="alltrip-table">
          <thead>
            <tr>
              <th>Trip ID</th>
              <th>Destination</th>
              <th>Starting</th>
              <th>Name</th>
              <th>Mobile No</th>
              <th>Departure Date</th>
              <th>Return Date</th>
              <th>Total Amount</th>
              <th>Paid Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {displayedTrips.length === 0 ? (
              <tr>
                <td colSpan="10">No trips available.</td>
              </tr>
            ) : (
              displayedTrips.map((trip, index) => {
                const isCompleted = trip.total_amount - trip.paid_amount <= 0;
                return (
                  <tr key={trip.trip_id} ref={index === displayedTrips.length - 1 ? lastElementRef : null}>
                    <td>
                      <Link to={`/tripdetails/${trip.trip_id}`} className="link">
                        {trip.trip_id}
                      </Link>
                    </td>
                    <td>{trip.destination}</td>
                    <td>{trip.starting_place}</td>
                    <td>{trip.customer_name}</td>
                    <td>{trip.customer_phone}</td>
                    <td>{new Date(trip.departure_date).toLocaleDateString('en-GB')}</td>
                    <td>{new Date(trip.return_date).toLocaleDateString('en-GB')}</td>
                    <td>{trip.total_amount}</td>
                    <td>{trip.paid_amount}</td>
                    <td>
                      <span className={`statustrip ${isCompleted ? 'completed' : 'pending'}`}>
                        {isCompleted ? 'Completed' : 'Pending'}
                      </span>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllTripList;
