import React, { useState } from 'react';
import '../../Quatation/Components/Form.css'; // Ensure you import the CSS file
import TextareaAutosize from "react-textarea-autosize";

const AddTripForm = () => {
    // State initialization with default values
    const [formData, setFormData] = useState({
        date: '', 
        discriptions: '',
        category: '',
        paid_by: '',
        amount: 0,
        remark: '',
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const apiUrl = process.env.REACT_APP_API_URL;
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform your submission logic here (e.g., sending formData to an API)
        try {
            const response = await fetch(`${apiUrl}/api/v1/user/create-account-details`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('token')}` // Add token to the Authorization header
                },
                body: JSON.stringify(formData),
              });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Success:', result);
            // Optionally reset form data or show success message
            setFormData({
                date: '', // Reset the date
                discriptions: '',
                category: '',
                paid_by: '',
                amount: 0,
                remark: '',
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="form-to-pdf">
            <h2>Add  Transaction</h2>
            <form onSubmit={handleSubmit}>
                

                <label>Date:</label> {/* New Date Field */}
                <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                />
                
                <label>Description:</label>
                <input
                    type="text"
                    name="discriptions"
                    value={formData.discriptions}
                    onChange={handleChange}
                    placeholder="Enter Description"
                    required
                />

                <label>Category:</label>
                <select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Category</option>
                    <option value="Sales">Sales</option>
                    <option value="Expenses">Expenses</option>
                    <option value="Marketing">Marketing</option>
                    <option value="B2b">B2B</option>
                </select>

                <label>Paid By:</label>
                <select
                    name="paid_by"
                    value={formData.paid_by}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Payer</option>
                    <option value="Yash">Yash</option>
                    <option value="Jaydip">Jaydip</option>
                    <option value="Bhargav">Bhargav</option>
                </select>

                <label>Amount:</label>
                <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    placeholder="Enter Amount"
                    required
                />

                <label>Remark:</label>
                <TextareaAutosize
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                    placeholder="Enter Remarks"
                    minRows={2}
                />

                <button type="submit" className="submit-button">Add Transaction</button>
            </form>
        </div>
    );
};

export default AddTripForm;
