import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "../Component/InvoiceDetails.css"; 
import logo from '../../assets/skylogo.jpg'; 
import watermarkImg from '../../assets/sign.jpg'; // Add your JPG image here
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from "Components/Loader";

const FormToPDF = () => {
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    customer_name: "",
    customer_phone: "",
    departure_place: "",
    arrival_place: "",
    departure_date: "",
    return_date: "",
    departure_ticket: '',
    return_ticket: '',
    day: 0,
    night: 0,
    total_amount: 0,
    adult: 0,
    children: 0,
    vehicle: "",
    meal_plan: "",
    resort_details: [],
    inclusion: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Get the token from local storage

        const response = await axios.get(`${apiUrl}/api/v1/user/get-trip-details/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the token
          },
        });

        setFormData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load trips');
        setLoading(false);
      }
    };
    fetchData();
  }, [id]); // Make sure to include 'id' in the dependency array

  const printDocument = () => {
    const input = document.querySelector(".a4-box");

    html2canvas(input, { scale: 4, scrollX: 0, scrollY: 0 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.9);
      const pdf = new jsPDF("p", "mm", "a4");

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= 297;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= 297;
      }

      const pdfFileName = `${formData.customer_name || "Guest"}_Invoice.pdf`;
      pdf.save(pdfFileName);
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="FormToPDF">
      <div id="divToPrint" className="pdf-preview">
        <div className="a4-box invoice-container">
          {/* Header Section */}
          <div className="header">
            <img src={logo} alt="SKY HOLIDAYS Logo" className="logo" />
            <div className="header-text">
              <h1>SKY HOLIDAYS</h1>
              <p>A-502, Steller, Sindhubhavan Road, Ahmedabad</p>
            </div>
          </div>
          
          <hr />
          
          {/* Customer Details */}
          <div className="invoice-customer-details">
            <p><strong>Invoice :</strong> {id}</p>
            <p><strong>Name:</strong> {formData.customer_name}</p>
            <p><strong>Mobile No:</strong> {formData.customer_phone}</p>
          </div>

          {/* Add space between customer details and table */}
          <div style={{ marginTop: "30px" }}></div>

          {/* Bill Table */}
          <h3 className="bill-details-title">Invoice Details</h3>
          <table className="invoice-bill-table invoice-bill-table-container ">
            
            <thead>
              <tr>
                <th>Destination</th>
                <th>Inclusion</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formData.destination}</td>
                <td>
                    {[
                      formData.destination && formData.destination.trim() !== '' && `${formData.destination}`,
                      formData.departure_date && String(formData.departure_date).trim() !== '' && `* Check in : ${new Date(formData.departure_date).toLocaleDateString('en-GB')}`,
                      formData.return_date && String(formData.return_date).trim() !== '' && `* Check Out : ${new Date(formData.return_date).toLocaleDateString('en-GB')}`,
                      formData.departure_place && String(formData.departure_place).trim() !== '' && `* Pickup From: ${formData.departure_place}`,
                      formData.arrival_place && String(formData.arrival_place).trim() !== '' && `* Drop To: ${formData.arrival_place}`,
                      formData.day && String(formData.day).trim() !== '' && `* ${formData.day} Days`,
                      formData.night && String(formData.night).trim() !== '' && `${formData.night} Nights`,
                      formData.adult && String(formData.adult).trim() !== '' && `* Adults: ${formData.adult}`,
                      formData.children && String(formData.children).trim() !== '' && `* Children: ${formData.children}`,
                      formData.vehicle && String(formData.vehicle).trim() !== '' && `* Vehicle: ${formData.vehicle}`,
                      formData.meal_plan && String(formData.meal_plan).trim() !== '' && `* Meal Plan: ${formData.meal_plan}`,
                    ]
                      .filter(Boolean) // Remove falsy values (null, undefined, empty strings, or strings with only spaces)
                      .join(' ')} {/* Join the array into a single string with space between each part */}
                  </td>

                <td>₹{formData.total_amount}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="2">Total</td>
                <td>₹{formData.total_amount}</td>
              </tr>
            </tfoot>
          </table>
         
          {Array.isArray(formData.resort_details) && formData.resort_details.length > 0 ? (
            formData.resort_details.map((resort, index) => (
              <div className="resort-option" key={index}>
             
                <div className="resort-details">
                  
                  <h3>{resort.resortDate}</h3>
                  <h4>{resort.resortName}</h4>
                  <p className="star-rating">
                    {Array.from({ length: resort.star }, (_, i) => (
                      <span key={i}>⭐</span>
                    ))}
                  </p>
                  <p>{resort.roomCategory}</p>
                  
                </div>
              </div>
            ))
          ) :  null}
          <div className="inclusion">
            <h4>Inclusion:</h4>
            <p>{formData.inclusion}</p>
          </div>
          {/* Centered Thank You Message */}
          <div className="thank-you-message">
            <p>Thank you for choosing SKY HOLIDAYS!</p>
          </div>

          {/* Image on Right Side */}
          <div className="watermark-section">
            <img src={watermarkImg} alt="Watermark" className="watermark-img" />
          </div>
        </div>
      </div>

      <div className="button-container">
        <button className="download-btn" onClick={printDocument}>Download PDF</button>
      </div>
    </div>
  );
};

export default FormToPDF;
